import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import ConfirmationModal from "../ConfirmationModal";
import Footer from "../Footer";
import Header from "../Header";

import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";
import Loader from "../Loader";

const AdminLayout = () => {
  return (
    <>
      <div className="AdminLayout">
        <div className="AdminLayout__header">
          <Header />
        </div>
        <div className="AdminLayout__main">
          <Outlet />
        </div>
        <div className="AdminLayout__footer">
          <Footer />
        </div>
      </div>
      {/* <ConfirmationModal /> */}
      <Loader />
      <ToastContainer />
    </>
  );
};

export default AdminLayout;
