import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import logo from "../../assets/logo_blue.svg";
import phone from "./images/phone.png";
import card1 from "./images/card1.png";
import card2 from "./images/card2.png";
import card3 from "./images/card3.png";
import card4 from "./images/card4.png";
import card5 from "./images/card5.png";

import "./styles.scss";

const Home = () => {
  return (
    <div className="Home">
      <div className="Home__banner">
        <div className="Home__banner__title">
          Bienvenidos a{" "}
          <div className="Home__banner__title__logo">
            <img src={logo} />
          </div>
        </div>
        <div className="Home__banner__subtitle">Pon de moda tu restaurante</div>
        <div className="Home__banner__button">
          <Link to="create-account">
            <Button variant="contained">Crea tu cuenta gratuita</Button>
          </Link>
        </div>
        <div className="Home__banner__demo">
          <a href="mailto:hello@wubalu.com">
            Envíanos un email para solicitar una demo
          </a>
        </div>
      </div>
      <div className="Home__section1">
        <div>
          <div className="Home__section1__card1 Home__section1__card">
            Haz que tus clientes hablen de ti,{" "}
            <span>pon tu restaurante de moda</span> y aprovecha el poder de las
            recomendaciones auténticas para <span>atraer nuevos clientes.</span>
          </div>
          <div className="Home__section1__card2 Home__section1__card">
            Wubalu es una herramienta innovadora que{" "}
            <span>convierte a tus clientes en tus mejores publicistas.</span>
          </div>
        </div>
        <div>
          <div className="Home__section1__card3 Home__section1__card">
            <div>
              <img src={phone} />
            </div>
            <div>
              A través de nuestra aplicación, tus consumidores{" "}
              <span>
                publican contenido sobre tu restaurante a cambio de recompensas
              </span>
              , ayudándote a atraer nuevos clientes y{" "}
              <span>aumentar tu presencia en redes sociales</span> de forma
              auténtica y efectiva.
            </div>
          </div>
        </div>
      </div>
      <div className="Home__section2">
        <div>
          <div className="Home__section2__card Home__section2__card--1">
            <div className="Home__section2__card__image">
              <img src={card1} />
            </div>
            <div>
              <div className="Home__section2__card__title">
                Mayor visibilidad
              </div>
              <div className="Home__section2__card__text">
                Aumenta tu presencia en Instagram a través de contenido generado
                por tus clientes.
              </div>
              <div className="Home__section2__card--1__bottom-image" />
            </div>
          </div>
        </div>
        <div>
          <div className="Home__section2__card Home__section2__card--2">
            <div className="Home__section2__card__image">
              <img src={card2} />
            </div>
            <div>
              <div className="Home__section2__card__title">
                Publicidad segmentada
              </div>
              <div className="Home__section2__card__text">
                El contenido llega al círculo de amigos y seguidores de tus
                clientes, alcanzando a personas realmente interesadas.
              </div>
            </div>
          </div>
          <div className="Home__section2__card Home__section2__card--4">
            <div className="Home__section2__card__image">
              <img src={card4} />
            </div>
            <div>
              <div className="Home__section2__card__title">Fidelización</div>
              <div className="Home__section2__card__text">
                Ofrecer premios por publicaciones incentiva a los clientes a
                regresar y seguir participando.
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="Home__section2__card Home__section2__card--3">
            <div className="Home__section2__card__image">
              <img src={card3} />
            </div>
            <div>
              <div className="Home__section2__card__title">
                Atraer nuevos clientes
              </div>
              <div className="Home__section2__card__text">
                Las publicaciones de tus clientes actúan como recomendaciones
                personales, atrayendo nuevos visitantes.
              </div>
            </div>
          </div>
          <div className="Home__section2__card Home__section2__card--5">
            <div className="Home__section2__card__image">
              <img src={card5} />
            </div>
            <div>
              <div className="Home__section2__card__title">
                Fomento del boca a boca
              </div>
              <div className="Home__section2__card__text">
                Las recomendaciones entre amigos y familiares son más efectivas
                y confiables.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Home__section3">
        <div className="Home__section3__card">
          <div className="Home__section3__card__info">
            <div className="Home__section3__card__title">
              Si deseas que tu restaurante forme parte de Wubalu y beneficiarse
              de una mayor presencia en redes sociales, contáctanos. Estamos
              aquí para ayudarte a crecer.
            </div>
            <div className="Home__section3__card__text">
              Envíanos un correo a{" "}
              <a href="mailto:hello@wubalu.com">hello@wubalu.com</a> para más
              información o para solicitar una demo.
            </div>
            <div className="Home__section3__card__cta">
              <a href="mailto:hello@wubalu.com">
                <Button variant="contained">Quiero ponerme en contacto</Button>
              </a>
            </div>
          </div>
          <div className="Home__section3__card__image" />
        </div>
      </div>
    </div>
  );
};

export default Home;
