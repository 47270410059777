const menuItems = [
  {
    path: "/",
    name: "Home",
  },
  {
    path: "/how-it-works",
    name: "Cómo funciona",
  },
  {
    path: "/pricing",
    name: "Precios",
  },
];

export default menuItems;
